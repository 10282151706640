import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Button, Checkbox, Box, IconButton } from '@mui/material';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import { useTranslation } from 'react-i18next';
import { fetchTopSubjects, getShareUrl } from '../services/apiService'; 
import './TopSubjects.css'; 
import config from '../config';
import PhraseSudokuGenerator from './PhraseSudokuGenerator';
import PhraseSudokuModal from './PhraseSudokuModal';

const TopSubjects = ({selectedAiLangs}) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedPhrases, setSelectedPhrases] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sudokuData, setSudokuData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(() => {
    const storedExpanded = localStorage.getItem('topSubjectsExpanded');
    return storedExpanded === null ? true : JSON.parse(storedExpanded);
  });

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const dataName = config.dataNames[0];
  const subjectName = dataName.subject;

  useEffect(() => {
    const loadTopSubjects = async () => {
      setLoading(true);
      try {
        const data = await fetchTopSubjects({page, aiLangs: selectedAiLangs});
        setSubjects(data);
      } catch (err) {
        setError('Failed to load top subjects');
      } finally {
        setLoading(false);
      }
    };

    loadTopSubjects();
  }, [page, selectedAiLangs]);

  useEffect(() => {
    localStorage.setItem('topSubjectsExpanded', JSON.stringify(isExpanded));
  }, [isExpanded]);

  const handleNext = () => setPage((prevPage) => prevPage + 1);
  const handlePrevious = () => setPage((prevPage) => Math.max(prevPage - 1, 1));

  const handlePhraseSelection = (subject) => {
    const phrase = subject[subjectName][0];
    setSelectedPhrases(prev => 
      prev.includes(phrase) ? prev.filter(p => p !== phrase) : [...prev, phrase]
    );
    setSelectedSubjects(prev =>
      prev.some(sub => sub[subjectName][0] === phrase) ?
        prev.filter(p => p[subjectName][0] !== phrase) :
        [...prev, subject]
    );
  };

  const handleSelectAll = () => {
    if (selectedPhrases.length === subjects.length) {
      setSelectedPhrases([]);
      setSelectedSubjects([]);
    } else {
      setSelectedPhrases(subjects.map(subject => subject[subjectName][0]));
      setSelectedSubjects(subjects);
    }
  };

  const generateSudoku = () => {
    if (selectedPhrases.length >= 9) {
      const generator = new PhraseSudokuGenerator(selectedPhrases);
      const data = generator.generate('medium');
      setSudokuData(data);
      setOpenModal(true);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const joinedLangs = aiField => aiField && Object.entries(aiField).map(([language, phrases], index) => (
    <React.Fragment key={language}>
      <span><i>{phrases[0]}</i></span><br />
    </React.Fragment>
  ));  

  return (
    <div className="top-subjects-container">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Typography className="section-header" gutterBottom>{t('topSubjects')}</Typography>
          <IconButton onClick={toggleExpand} size="small">
            {isExpanded ? <ExpandLessIcon style={{color: '#8ecae6', border: 'dashed #8ecae6'}} /> : <ExpandMoreIcon style={{color: '#8ecae6', border: 'dashed #8ecae6'}} />}
          </IconButton>
        </Box>
        <Box display="flex" alignItems="right">
          {isExpanded && (
            <Button 
              variant="outlined" 
              color="primary" 
              onClick={handleSelectAll}
              startIcon={<SelectAllIcon />}
            >
              {selectedPhrases.length === subjects.length ? 'Deselect All' : 'Select All'}
            </Button>          
          )}
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={generateSudoku} 
            disabled={selectedPhrases.length < 9}
            style={{ marginLeft: '10px' }}
            startIcon={<DynamicFormIcon />}
          >
            Phrases Puzzle
          </Button>  
        </Box>
      </Box>
      {isExpanded && (
        <>
          <Grid container spacing={1}>
            {subjects.map((subject, index) => (
              <Grid item xs={12} sm={6} md={3} key={`subject-${subject.id}-${index}`}>
                <Card className="subject-card">
                  <CardContent>
                    <Typography variant="h8" component="div">
                      <Checkbox
                        checked={selectedPhrases.includes(subject[subjectName][0])}
                        onChange={() => {
                          handlePhraseSelection(subject[subjectName][0]);
                        }}
                      />
                      <a href={getShareUrl(subject[subjectName], lang, selectedAiLangs)} target="_blank" rel="noopener noreferrer" className="subject-link">
                        <span style={{fontSize:'smaller'}}>{index + 1 + (page - 1) * 12}. </span>
                        <span style={{textTransform:'capitalize'}}>{subject[subjectName][0]}</span>
                        <sup style={{fontSize:'x-small'}}> ({subject['count']})</sup>
                      </a>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {joinedLangs(subject[`${dataName.aiFields.similarPhrases}`])}
                    </Typography>                
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <div className="pagination-buttons">      
            <Button variant="contained" color="inherit" onClick={handlePrevious} disabled={page === 1} style={{ marginLeft: '10px' }}>
              Previous 12
            </Button>
            <Button variant="contained" color="inherit" onClick={handleNext} style={{ marginLeft: '10px' }}>
              Next 12
            </Button>
          </div>
        </>
      )}
      <PhraseSudokuModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sudokuData={sudokuData}
        selectedSubjects={selectedSubjects}
      />
    </div>
  );
};

export default TopSubjects;